.active {
    background: rgba(228, 97, 15, 0.1) !important;
}

.in-active { 
    background: inherit !important;
    color: gray !important;
}

.list-sub-item-con::before { 
    content: "";
    height: 95%;
    width: .1rem;
    display: block;
    position: absolute;
    top: 2%;
    left: 40px;
    background-color: gray;
    opacity: 70%;
}
.list-sub-popup-item-con::before { 
    height: 90%;
    top: 4%;
    left: 20px;
    opacity: 50%;
}